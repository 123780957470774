(function ($) {
    "use strict";

    $(window).load(function () {
        var gallery_filter = $('.wrapper-gallery-filter');
        $(gallery_filter).magnificPopup({
            type: 'image',
            delegate: 'a',
            gallery: {
                enabled: true
            },
            mainClass: 'mfp-sc-filter-gallery',
            zoom: {
                enabled: true,
                duration: 300, // don't forget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            }
           
        });

        // filter items on button click
        $('.filter-controls').on('click', 'a', function () {
            if (!$(this).hasClass('active')) {
                $('a.filter').removeClass('active');
                $(this).addClass('active');
            }
            var filterValue = $(this).attr('data-filter');
            gallery_filter.isotope({filter: filterValue});
        });

        gallery_filter.isotope();
        

    });

})(jQuery);

